<template>
  <div>
    <div class="d-flex justify-content-center text-center mt-2">
      <b-row>
        <b-col md="12">
          <div> <h1> <strong>FICHE DE RENSEIGNEMENT ARRIVÉE À L'AÉROPORT DU COTONOU</strong> </h1>  </div>
        </b-col>
      </b-row>
    </div>

    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> RENSEIGNEMENTS GÉNÉRAUX </strong>
        </b-col>
      </b-row>
    </div>

    <b-card-body class=" nopadding">
      <div class="pl-1 pr-1 pt-1">
        <b-row>
          <b-col
            md="6"
            class=""
          >
            Date d'arrivée au Bénin: <strong> {{ validOutput.dateArrivee }} </strong>
          </b-col>
          <b-col
            md="6"
            class=""
          >
            Compagnie aérienne: <strong> {{ validOutput.compagnieAerienne }} </strong>
          </b-col>
          <b-col
            md="6"
            class=""
          >
            Pays de résidence: <strong> {{ validOutput.paysResidence }} </strong>
          </b-col>
          <b-col
            md="6"
            class=""
          >
            Nationalité: <strong> {{ validOutput.nationalite }} </strong>
          </b-col>
        </b-row>
      </div>
    </b-card-body>

    <!-- renseignement generaux -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> DÉTAILS  </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row>
          <b-col
            md="4"
            class="mb-1"
          >
            Nom et prénom: <strong> {{ validOutput.fullname }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Age: <strong> {{ validOutput.age }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Sexe: <strong> {{ validOutput.sexe }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Motif principal du voyage: <strong> {{ validOutput.motifVoyage }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Mode d'hébergemnt: <strong> {{ validOutput.modeHebergement }} </strong>
          </b-col>

          <b-col
            md="4"
            class="mb-1"
          >
            Ville/Commune: <strong> {{ validOutput.villeCommune }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Durée du séjour: <strong> {{ validOutput.dureeSejour }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Nom de l'agent enquêteur: <strong> {{ validOutput.enqueteur }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Allez-vous recevoir une rémunération d'une entité du pays/lieu visite durant votre séjour ? <strong> {{ validOutput.possibiliteRemuneration ? 'oui' : 'non'}} </strong>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BFormInput,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Logo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormInput,
    BFormGroup,
    BRow,
    vSelect,
    Logo,

  },
  directives: {
    Ripple,
  },
  props: {
    validOutput: Object,
  },
  data() {
    return {
    }
  },

  mounted() {
    // this.validOutput.agrementMinistere = 'non'
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.title-background{
  background-color: #ababab;
}

.tableHeadFontSize{
  font-size: 10px;
}
</style>
